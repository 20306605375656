// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}

//// TRANSITION
@mixin transition($properties...) {

  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  }

  @else {
    @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
  }
}
