// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
$screen-xs:                  1px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

//Additional breakpoint at 480;

$screen-tn:                  480px !default;
$screen-tn-min:              $screen-tn;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum

$screen-tn-less:             ($screen-tn-min - 1) !default;
$screen-xs-max:              ($screen-tn-min - 1) !default;
$screen-tn-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-lg-max:              1599px !default;


//override bootstrap border-radius
$border-radius-none:        0px !default;

// Grid system
// --------------------------------------------------

// Number of columns in the grid system
$grid-columns:              24 ;

$grid-min-width:            768px;
$tablet-md-width:           850px;

// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width:         20px;

/*dimensions*/
$container-max-width:1700px;

/* fonts */
$defaultfont: 'Droid Sans', sans-serif;

/* colors */
$darkgray: #2b2f3b;
$darkgrayalt: #4C4C4E;
$lightgray: #333;
$white: #ffffff;
$lightgraybackground: #F2F2F2;
$lightgreenish: #B8C1BC;
$lightgreenishalt:#B5B5B5;
$green: #64BD8D;


$modal-lg-alt:                    900px !default;
$modal-md-alt:                    900px !default;
$modal-sm:                    300px !default;
