.tw-footer {
    min-height: 300px;
    background: #313745;
    color:#747E94;
    padding-bottom:0;

    @media (max-width: 767px) {
        text-align: center;

        .img-responsive {
            margin: 10px auto;
        }
    }

    .btn-group {
        margin-bottom: 30px;

        &.open .dropdown-toggle {
            background-color: #2B2F3B;
            color: #fff;
        }

        .dropdown-toggle {
            background: #2B2F3B;
            margin-top: 3px; margin-bottom:0; color: #fff;border: none;min-width: 130px;
            &:hover {
                background: #2B2F3B;
                border: none;
            }
        }
        
        .dropdown-menu {
            border-radius: 0;
            background-color: #2B2F3B;
        }
        .dropdown-menu > li > a, .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
            background-color: #2B2F3B;
            color: #fff;
        }

    }

    .tw-footer-inner {
        padding-top: 80px;

        .tw-footer-headings {
            color: #fff;
            font-weight: bold;
            margin-bottom: 14px;
        }

        .tw-footer-links {
            padding: 0;
            list-style: none;
        }

        .tw-footer-links a {
            color: #fff;
            word-break: break-all;
        }

        .tw-footer-social a {
            color: #fff;
            display: inline-block;
            border-radius: 6px;
            overflow: hidden;
        }

        .tw-footer-social a i {
           font-size: 35px;
        }

    }

    .tw-footer-end {
        background-color: #2B2F3B;
        min-height: 100px;

        h3 {
            color: $white;
        }

        h6 { 
            width:100%; text-align:center; border-bottom: 1px solid #747E94; line-height:0.1em; margin:10px 0 20px; 
            span { background: #2B2F3B; padding:0 10px; }
        }

        .tw-footer-end-copyright {
            font-size: 11px;
            margin-bottom: 20px;
            display: block;
        }
    }
}