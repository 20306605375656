.tw-twocol-one {
    margin-top: 80px;

    .tw-meta-cat-name {
        color: $lightgray;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .tw-meta-cat-desc {
        color: $darkgrayalt;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin: 20px auto 40px auto;
    }

    .tw-twocol-col {
        margin-bottom: 20px;
    }

    .tw-twocol-col:nth-child(2n+1) {
        clear:left;
    }

    .tw-twocol-img-container {
        width: 100%;
        padding-bottom: 56.25%;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #D8D8D8;
        background-position: center center;
    }

    .tw-twocol-col-inner {
        background-color: $white;
        min-height: 300px;
        box-sizing:border-box;
        border: 1px solid #fff;

        &:hover { border: 1px solid #eee; }

        h6 { 
            font-size: 22px; font-weight: normal; color: #3B3B3C; text-align: left; margin-bottom:0;
            a { color: #3B3B3C; text-decoration:none; word-break: break-word; }
        }
        .tw-twocol-catname {
            color: $green;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 2px;
            &i {
                color: $green;
            }
        }

        .tw-twocol-col-txt, .tw-twocol-col-txt-alt { 
            padding: 15px;
            color: $lightgreenishalt;
        }

        .tw-twocol-col-p {
            margin: 20px 0;
        }

        a.btn.btn-info {
            clear: left;
            position: relative;
            display: inline-block;
            background: $lightgreenish;
            border-color: $lightgreenish;
        }

        .tw-tag-label {
            background-color: #95979e;
            padding: 5px 5px;
            border-radius: 0;
            margin: 0 2px 2px 0;
            display: inline-block;
        }

        .tw-twocol-tags {
            margin-top: 10px;
        }

        .tw-fav-icon.clicked {
            color: $btn-danger-bg;
        }
    }
}