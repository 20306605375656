#natural-contact{
    margin-top: 30px;
    background-color: #fff;

    p{
        font-size:1.6em !important;
        margin-bottom:1em;
        line-height:1.6;
        color: $lightgray;
        @media (max-width: $screen-sm-max) {
            font-size:1.3em !important;
            line-height:1.6;
        }
    }

    .textinput{
        background: none;
        border: 0;
        border-bottom: 1px dashed $lightgray;
        width: auto;
        font-size: 1em;
        color: $lightgray;
        font: inherit;
        padding:0 12px;
        outline:none !important;
        resize:none !important;
        box-sizing: border-box;
        transition: all 0.5s linear;
        -webkit-transition: all 0.5s linear;
        &:focus {
            transform: scale3d(1.01,1.01,1);
            -webkit-transform: scale3d(1.01,1.01,1);
            -ms-transform: scale3d(1.01,1.01,1);
            -moz-transform: scale3d(1.01,1.01,1);
            border-bottom: 1px dashed #DD0000;
        }
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
        color: rgba(149, 151, 158, 0.5);
        font-style: italic;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: rgba(149, 151, 158, 0.5);
        opacity: 1;
        font-style: italic;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: rgba(149, 151, 158, 0.5);
        opacity: 1;
        font-style: italic;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color: rgba(149, 151, 158, 0.5);
        font-style: italic;
    }
    
    :-moz-ui-invalid:not(output) {
        box-shadow: none;
    }

    select.dropdown {
        font-size: 1em;
        color: $lightgray;
        border: none;
        border-bottom: 1px dashed $lightgray;

        background: none;
        width: auto;
        text-indent: 0.01px;
        text-overflow: "";
        box-shadow: none;
        font: inherit;

        transition: all 0.5s linear;
        -webkit-transition: all 0.5s linear;
        &:focus {
            transform: initial;
        }
    }

    select.dropdown:focus {
        outline: none;
        border-bottom: 1px dashed #DD0000;
    }

    .txtstuff {
        resize: none; /* remove this if you want the user to be able to resize it in modern browsers */
        overflow: hidden;
    }

    /* the styles for 'commmon' are applied to both the textarea and the hidden clone */
    /* these must be the same for both */
    .common {
        width: 100%;
        height: 72px;
        font-size: 18px;
        line-height: 18px;
        overflow: hidden;
        font-style: italic;
        transition: all 0.5s linear;
        -webkit-transition: all 0.5s linear;
    }

    .common.expanded {
        height: 144px;
    }

    .lbr {
        line-height: 3px;
    }

    button.submit{
        border:none;
        background-color:#2b2f3b;
        color:#fff;
        padding:5px 20px;
        transition:opacity 0.2s;
        -webkit-transition:opacity 0.2s;
        -moz-transition:opacity 0.2s;

        &:hover{
            opacity:0.8;
        }
    }
}