.tw-art-details-hero-back {
    position: absolute;
    background-color: #F2F2F2;
    top: -100px;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 600px;
    overflow: hidden;

    -webkit-filter: blur(1px) brightness(0.7);
    -moz-filter: blur(1px) brightness(0.7);
    -ms-filter: blur(1px) brightness(0.7);
    filter: blur(1px) brightness(0.7);

    img {
        width: 100%;
        height: auto;
        position:relative;
    }

}

.tw-art-details-content-inner {
    margin-top: 300px;
    background-color:$white;
    min-height: 500px;
    padding: 20px;
    color: $lightgray;

    .latestsectiontitle {
        color: #64BD8D;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 2px;
        margin: 30px auto;
    }

    .sidebarimg {
        width: 300px;
        height: auto;
        max-width: 100%;
    }
    .sidebarbox {
        width: 300px;
        height: auto;
        max-width: 100%;
        background-color: $white;
        margin: 0 auto 30px auto;
        overflow: hidden;
        padding: 10px 0 0 0;;

        a h6 {
            color: $darkgray;
            text-decoration: none;
            word-break: break-word;
            font-weight: bold;
            font-size: 15px;
        }

        &:hover {
        }
    }

    #fullartintro p {
        font-size: 16px;
        font-weight: bold;
    }

    @media (max-width: 767px) {
        margin-top: 100px;
    }

    h1 {
        font-weight: bold;
        word-break: break-word;
    }

    .tw-twocol-catname {
        color: $green;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 2px;
        &i {
            color: $green;
        }
    }
    .tw-twocol-col-txt { 
        padding: 15px;
        color: $lightgreenishalt;
    }

    .tw-twocol-col-p {
        margin: 20px 0;
    }

    a.btn.btn-info {
        clear: left;
        position: relative;
        display: inline-block;
        background: $lightgreenish;
        border-color: $lightgreenish;
    }

    .tw-tag-label {
        background-color: #95979e;
        padding: 5px 20px;
        border-radius: 0;
        margin-bottom: 2px;
        display: inline-block;
    }

    .tw-twocol-tags {
        margin-top: 10px;
    }

    .tw-fav-icon.clicked {
        color: $btn-danger-bg;
    }

    .tw-art-details-author-meta {
        font-size: 12px;
        p { 
            margin-top: 13px;
        }
    }

    .tw-ad-leader-container {
        margin: 20px auto;
    }



}