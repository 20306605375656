
.tw-cta-large-banner {
    position:relative;
    padding-bottom:0;
    .tw-absolute-container {
        position: absolute; 
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: #ffffff;
        background: rgba(0, 0, 0, 0.46);
        width: 50%;
        left: 50%;
        padding: 10px;

        @media (max-width: $screen-sm-max) {
            width: 100%;
            left: 0;
            transform: translate3d(0, -50%, 0);
        }

        @media (max-width: $screen-sm-min) {
            width: 100%;
            left: 0;
            top: 0;
            position:static;
            background: #313745;
            transform: translate3d(0, 0, 0);

            .row {
                max-width: initial !important;
            }
        }

    }

    .tw-largebanner-submit {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .tw-relative-container {
        min-height: 300px;
        background: $white;
    }
}