.tw-fourcol-one {
    margin-top: 80px;

    &:first-child {
        /* margin-top: -20px; */
    }

    .tw-meta-cat-name {
        color: $lightgray;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .tw-meta-cat-desc {
        color: $darkgrayalt;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin: 20px auto 40px auto;
    }

    .tw-fourcol-imgcontainer {
        width: 100%;
        padding-bottom: 56.25%;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #D8D8D8;
        background-position: center center;
    }

    .tw-fourcol-col {
        margin-bottom: 30px;
    }

    .tw-fourcol-col:nth-child(4n+1) {
        clear:left;
    }

    .tw-fourcol-col-inner {
        background-color: $white;
        min-height: 300px;
        box-sizing:border-box;
        border: 1px solid #fff;

        &:hover { border: 1px solid #eee; }
        
        h6 { 
            font-size: 18px; font-weight: bold; color: #3B3B3C; text-align: left; text-decoration:none;
            a { color: #3B3B3C; text-decoration:none; word-break: break-word; }
        }
        .tw-fourcol-col-txt { 
            padding: 15px;
            color: $lightgreenishalt;

            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            p {
                flex-grow: 1;
            }
        }

        .tw-fourcol-col-p {
            margin: 20px 0;
        }

        a.btn.btn-info {
            clear: left;
            position: relative;
            display: inline-block;
            background: $lightgreenish;
            border-color: $lightgreenish;
        }
    }
}