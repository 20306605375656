
.tw-featuredin {
    .tw-overlapping-container {
        margin-top: -100px;
        background-color: white;
        min-height: 200px;
        padding: 20px;
        box-sizing: border-box;
    }
}
