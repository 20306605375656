
.tw-ad-leader-container {
    max-width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 60px;

    img {
        max-width: 100%;
        margin: auto;
    }
}

