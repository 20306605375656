.tw-hero {
    position: relative;
    margin-top: 0;
    z-index: 1;
    transform: translate3d(0,0,0);
    transform: translate3d(0,0,0) !important;
    -webkit-transform: translate3d(0,0,0) !important;
    -moz-transform: translate3d(0,0,0) !important;
    -ms-transform: translate3d(0,0,0) !important;
    transition: all 0s linear;
    -webkit-transition: all 0s linear;

    .carousel-inner { 
        transform: translate3d(0,0,0) !important;
        -webkit-transform: translate3d(0,0,0) !important;
        -moz-transform: translate3d(0,0,0) !important;
        -ms-transform: translate3d(0,0,0) !important;

        .carousel-control {
            @media (max-width: $screen-sm-max) {
                background: transparent;
                color: #FFF;
                opacity: 1;
            }
        }

        .tw-carousel-arrow {
            top: 50%;
            position: absolute;
            display: inline;
            transform: translate3d(-50%, -50%, 0);
            font-size: 5em;
        }
    }
}

.notifications-wrapper.hidden + .menu-wrapper { margin-top: 0; }

.tw-hero-img-container {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
/*     height: calc(100% - 100px);
    max-height: calc(100vh - 50px); */

    .tw-hero-img {
        min-width: 100%;
        height: auto;
        max-height: 100%;
    }

    .tw-hero-heading {
        width: 100%;
        bottom: 10%;
        background-color: transparent;
        text-align: center;
        font-weight: bold;
        color: $white;
        position:absolute;
        text-shadow: 0 0 20px #000;
        word-break: break-word;
        padding-left: 10%;
        padding-right: 10%;
        @media (max-width: $screen-sm-max) {
            position: relative;
            bottom: auto;
            background: #2B2F3B;
            margin: 0;
            padding: 10px 30px;
            word-break: break-word;
            font-size: 20px;
        }

        a {
            color: $white;
            text-decoration: none;
        }
    }
}