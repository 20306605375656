.notifications-wrapper {
    $nwh: 50px;
    min-height: $nwh;
    background-color: $darkgray;
    color: $white;
    line-height: $nwh;
    font-weight: bold;
    z-index:16;
    position:fixed;
    left:0;
    right:0;
    width:100%;

    #cookieconsent {
        p {
            justify-content: center;
            width: calc(100% - 20px);
            margin: 10px auto;
            height: auto;
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            line-height:14px;
        }
    }

    .notifications-dismiss {
        position: absolute;
        color: #95979e;
        right: 10px;
        width: 15px;
        top: 0;
        cursor: pointer;
        height: 100%;
        font-size:1.3em;
    }
}
