/*importing bootstrap */
@import 'bootstrap-sass-3.3.5/assets/stylesheets/_bootstrap.scss';

@import '../css/admin.screen.css';
@import 'variables.scss';
@import 'mixins.scss';
@import 'globals.scss';

@import 'partials/socialslide.scss';
@import 'partials/hero.scss';
@import 'partials/notifications.scss';
@import 'partials/menu.scss';
@import 'partials/fourcolone.scss';
@import 'partials/twocolone.scss';
@import 'partials/footer.scss';
@import 'partials/largebanner.scss';
@import 'partials/featuredin.scss';
@import 'partials/ads.scss';
@import 'partials/loginmodal.scss';


@import 'partials/artdetails.scss';
@import 'partials/naturalform.scss';