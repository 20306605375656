#tw-social-slide {
    .tw-social-slide-item {
        margin-top: 200px;
    }

    .tw-social-slide-text {
        position: absolute;
        top: -150px;
        padding: 40px;
        box-sizing: border-box;
        background: $white;
        height: 370px;
        overflow: hidden;

        .tw-social-slide-text-inner {
            text-align: center;

            i {
                color: $green;
                font-size: 26px;
            }

            p {
                font-size: 26px;
                color: $lightgreenishalt;
                line-height: 38px;
                margin-top: 0;
                margin-bottom: 26px;
                word-break: break-all;
            }

            .quote-name {
                font-size: 14px;
                letter-spacing: -0.01em;
                margin-bottom: 7px;
                text-transform: capitalize;
                font-weight: 700;
                margin: 0;
                line-height: 34px;
                color: $darkgrayalt;
            }

            .quote-desc {
                color: $lightgray;
                font-size: 13px;
                margin: 0;
                line-height: 14px;
            }
        }
    }

    .carousel-inner {
        position: relative;
        overflow: hidden;
        width: 100%;
        transform: translate3d(0,0,0);
        z-index: 1;
    }

    .carousel-indicators {
        position: absolute;
        top: 370px;
        color: $green;
        height: 20px;

        li, li.active {
            background: $green;
        }
    }
}
