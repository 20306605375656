.menu-wrapper {
    background: rgba(0, 0, 0, 0.27);
    height: 100px;
    line-height: 100px;
    position: absolute;
    z-index: 2;
    width: 100%;
    margin-top: 50px;
    overflow: hidden;

    &.notifhidden {
        margin-top: 0;
    }

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.81+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.81) 0%, rgba(0,0,0,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.81)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.81) 0%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0.81) 0%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.81) 0%,rgba(0,0,0,0) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.81) 0%,rgba(0,0,0,0) 100%); /* W3C */

    @media (max-width: 767px) {
        overflow: visible;
        line-height: initial;

        .fixedonmobile {
            position: fixed;
            z-index: 38;
            top: 0;
            left: 0;
            width: 80%;
            height: calc(100% + 80px);
            background-color: #2B2F3B;
            padding-top: 100px;
            text-align: left;
            overflow: auto;
            padding-bottom: 80px;

            .tw-nav {
                display: block;
                line-height: 20px;
                line-height: 50px;
            }

            span.spacer {
                display: block !important;
                width: 100% !important;
                border-bottom: 1px solid rgba(255, 255, 255, 0.11);
            }
        }

        #close_menu {
            float: right;
            display: block;
            line-height: 14px;
            font-size: 40px;
            top: -30px;
            position: relative;
            color: #fff;
        }

        .close-menu {
            width: 32px;
            height: 32px;
            position: absolute;
            right: 0;
            top: 0;
            overflow: hidden;
            text-indent: 100%;
            cursor: pointer;
            opacity: 0;
            -webkit-transform: scale3d(0, 0, 1);
            transform: scale3d(0, 0, 1);
            opacity: 1;
            pointer-events: auto;
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
            transition: opacity 0.3s, transform 0.3s;
            -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
        }

        .close-menu::before,
        .close-menu::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 100%;
            top: 0;
            left: 50%;
            border-radius: 3px;
            opacity: 0.6;
            background: #fff;
        }

        .close-menu:hover.close-menu::before,
        .close-menu:hover.close-menu::after {
            opacity: 1;
        }

        .close-menu::before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .close-menu::after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }

    .tw-brand-image { max-width:100%; }

    .tw-nav {
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: bold;
    }

    .spacer { width: 5px; display:inline-block; }

    #hamburger_menu {
        font-size: 30px;
        color: #fff;
        float: left;
        min-width: 40px;
    }
}

.morphsearch {
    width: 200px;
    min-height: 40px;
    background: $white;
    position: absolute;
    z-index: 100;
    top: 78px;
    right: 40px;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition-property: min-height, width, top, right;
    transition-property: min-height, width, top, right;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);

    &:after {
        content: " ";
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 6px;
        background: transparent url(../images/magnifier_alt.svg) no-repeat center center;
        background-size: contain;
    }

    &.notifhidden {
        top: 28px;
    }
}

.morphsearch.open {
    width: 100%;
    min-height: 100%;
    top: 0px;
    right: 0px;
    &:after {
        display: none;
    }
}

.morphsearch-form {
    width: 100%;
    height: 40px;
    margin: 0 auto;
    position: relative;
    -webkit-transition-property: width, height, -webkit-transform;
    transition-property: width, height, transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.morphsearch.open .morphsearch-form {
    width: 80%;
    height: 160px;
    -webkit-transform: translate3d(0,3em,0);
    transform: translate3d(0,3em,0);
}

.morphsearch-input {
    width: 100%;
    height: 100%;
    padding: 0 10% 0 10px;
    font-weight: 700;
    border: none;
    background: transparent;
    font-size: 1em;
    color: $green;
    -webkit-transition: font-size 0.5s cubic-bezier(0.7,0,0.3,1);
    transition: font-size 0.5s cubic-bezier(0.7,0,0.3,1);
}

.morphsearch-input::-ms-clear { /* remove cross in IE */
    display: none;
}

.morphsearch.hideInput .morphsearch-input {
    color: transparent;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.morphsearch.open .morphsearch-input {
    font-size: 7em;
}

/* placeholder */
.morphsearch-input::-webkit-input-placeholder {
    color: $lightgreenish;
}

.morphsearch-input:-moz-placeholder {
    color: $lightgreenish;
}

.morphsearch-input::-moz-placeholder {
    color: $lightgreenish;
}

.morphsearch-input:-ms-input-placeholder {
    color: $lightgreenish;
}

/* hide placeholder when active in Chrome */
.gn-search:focus::-webkit-input-placeholder {
    color: transparent;
}

input[type="search"] { /* reset normalize */
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
}

.morphsearch-input:focus,
.morphsearch-submit:focus {
    outline: none;
}

.morphsearch-submit {
    position: absolute;
    width: 80px;
    height: 80px;
    text-indent: 100px;
    overflow: hidden;
    right: 0;
    top: 50%;
    background: transparent url(../images/magnifier.svg) no-repeat center center;
    background-size: 100%;
    border: none;
    pointer-events: none;
    transform-origin: 50% 50%;
    opacity: 0;
    -webkit-transform: translate3d(-30px,-50%,0) scale3d(0,0,1);
    transform: translate3d(-30px,-50%,0) scale3d(0,0,1);
}

.morphsearch.open .morphsearch-submit {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(-30px,-50%,0) scale3d(1,1,1);
    transform: translate3d(-30px,-50%,0) scale3d(1,1,1);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

.morphsearch-close {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 1em;
    top: 1em;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    -webkit-transform: scale3d(0,0,1);
    transform: scale3d(0,0,1);
}

.morphsearch.open .morphsearch-close {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

.morphsearch-close::before,
.morphsearch-close::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    border-radius: 3px;
    opacity: 0.2;
    background: #000;
}

.morphsearch-close:hover.morphsearch-close::before,
.morphsearch-close:hover.morphsearch-close::after {
    opacity: 1;
}

.morphsearch-close::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.morphsearch-close::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.morphsearch-content {
    color: #333;
    margin-top: 4.5em;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0 10.5%;
    background: #f1f1f1;
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

.morphsearch.open .morphsearch-content {
    opacity: 1;
    height: auto;
    overflow: visible; /* this breaks the transition of the children in FF: https://bugzilla.mozilla.org/show_bug.cgi?id=625289 */
    pointer-events: auto;
    -webkit-transition: opacity 0.3s 0.5s;
    transition: opacity 0.3s 0.5s;
}

.dummy-column {
    width: 30%;
    padding: 0 0 0.2em;
    float: left;
    opacity: 0;
    -webkit-transform: translate3d(0,100px,0);
    transform: translateY(100px);
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;

    &:nth-child(3n+2) {
        margin: 0 5%;
    }
}

.morphsearch.open .dummy-column:first-child {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.morphsearch.open .dummy-column:nth-child(2) {
    -webkit-transition-delay: 0.45s;
    transition-delay: 0.45s;
}

.morphsearch.open .dummy-column:nth-child(3) {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

.morphsearch.open .dummy-column {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.dummy-column h2 {
    font-size: 1em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
    color: #c2c2c2;
    padding: 0.5em 0;
}

.round {
    border-radius: 50%;
}

.dummy-media-object {
    padding: 0.75em;
    display: block;
    margin: 0.3em 0;
    cursor: pointer;
    border-radius: 5px;
    background: rgba(118,117,128,0.05);
}

.dummy-media-object:hover,
.dummy-media-object:focus {
    background: rgba(118,117,128,0.1);
    text-decoration: none;
}

.dummy-media-object img {
    display: inline-block;
    width: 50px;    
    height: 50px;
    margin: 0 10px 0 0;
    vertical-align: middle;
}

.dummy-media-object h3 {
    vertical-align: middle;
    font-size: 0.85em;
    display: inline-block;
    font-weight: 700;
    margin: 0 0 0 0;
    width: calc(100% - 70px);
    color: rgba(145,145,145,0.7);
}

.dummy-media-object:hover h3 {
    color: #64BD8D;
}

/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.morphsearch.open ~ .overlay {
    opacity: 1;
}

@media screen and (max-width: 53.125em) {
    .morphsearch-input {
        padding: 0 25% 0 10px;
    }
    .morphsearch.open .morphsearch-input {
        font-size: 2em;
    }
    .dummy-column {
        float: none;
        width: auto;
        padding: 0 0 2em;
    }
    .dummy-column:nth-child(3n+2) { 
        margin: 0;
    }
    .morphsearch.open .morphsearch-submit {
        -webkit-transform: translate3d(0,-50%,0) scale3d(0.5,0.5,1);
        transform: translate3d(0,-50%,0) scale3d(0.5,0.5,1);
    }
}

@media screen and (max-width: 60.625em) {
    .morphsearch {
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        background: #2B2F3B;
        min-height: 50px;
        z-index: initial;
        line-height: 50px;

        &.notifhidden {
            top: 0;
            z-index: 100;
        }

        &.open {
            background: #f1f1f1;
            overflow: auto;
        }

    }

    .menu-wrapper.notifhidden {
        margin-top: 50px;
    }

}