.tw-login-modal-wrapper {
    padding:0;
}
#tw-loginmodal {
    #tw-loginmodal-body {
        .tw-twocol-one {
            margin-top: 0;
            .tw-twocol-col-inner {
                min-height: 150px; background-color: $lightgraybackground; margin-top: 20px; cursor:pointer;
                &.selected {
                    background-color: #3A7EB9;
                        .tw-twocol-catname {
                            span {
                                background-color: #3A7EB9;
                                color:#FFF;
                            }
                        }

                        &:hover {
                            background-color: #3A7EB9;
                                .tw-twocol-catname {
                                    span {
                                        background-color: #3A7EB9;
                                        color:#FFF;
                                    }
                                }
                        }
                }

                &:hover {
                    background-color: $white;
                        .tw-twocol-catname {
                            span {
                                background-color: $white;
                            }
                        }
                }

                .label {
                    padding: 10px 20px;
                    border-radius: $border-radius-none;
                    cursor:pointer;
                }
            }
        }

        h5 {
            width: 100%; border-bottom: 1px solid $lightgray; line-height: 0.1em; margin: 40px 0 20px; 
            &.tw-twocol-catname {
                span {
                    color: $green;
                    background-color: $lightgraybackground;
                }
            }
            span {
                background-color: $white;
                padding: 0 10px;
                color:$lightgray;
            }
        }

        .form-signup-heading, .form-signin-heading {
            color:$lightgray;
        }

    }

    .tw-loginmodal-nav-tabs {
        top: 16px;
        position: relative;
    }

    .centered-pills { text-align:center; }
    .centered-pills ul.nav-pills { display:inline-block; }
    .centered-pills li { display:inline; }
    .centered-pills a { float:left; padding: 5px; }
    * html .centered-pills ul.nav-pills { display:inline; } /* IE6 */
    *+html .centered-pills ul.nav-pills { display:inline; } /* IE7 */


    .tw-submit-article-form {
        margin-top: 20px;
    }
}


#tw-opencases-tab-sub {
    .panel-title {
        font-size: 14px;
    }

    .list-group-item {
        border-radius: 0;
    }

    .list-group-item-heading {
        font-size: 12px;
    }

    .list-group-item-text {
        font-size: 12px;
    }

    .tw-message-status {
        cursor: pointer;
    }
}